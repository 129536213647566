import * as React from "react";
import "./blog.scss";
import { AllWordpressPostQuery } from "graphql-types";
import Hero, { headerStyle } from "src/components/Organisms/Hero/hero";
import { graphql, Link, navigate, useStaticQuery } from "gatsby";
import moment from "moment";
import Pagination from "rc-pagination";
import i18next from "i18next";
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';


import { useQuery } from '@apollo/react-hooks';
import { useEffect, useState } from "react";

const { FontAwesomeIcon } = require('@fortawesome/react-fontawesome')
const { faCalendarAlt, faTag } = require('@fortawesome/free-solid-svg-icons')

// const lang = ["eq" , "ne"]


export const pageQuerySc:any = graphql`
  query AllWordpressPost ($skip: Int!, $limit: Int!) {


    english: allWordpressPost(
      sort: { fields: [date], order: DESC }
      filter: {categories: {elemMatch: {name: {eq: "Sc"} } } }
      limit: $limit
      skip: $skip
      ) {
      nodes {
        id
        path
        date
        title
        excerpt
        slug
        categories {
          name
          link
          slug
        }
      }
    },

    chinese: allWordpressPost(
      sort: { fields: [date], order: DESC }
      filter: {categories: {elemMatch: {name: {ne: "Sc"} } } }
      limit: $limit
      skip: $skip
      ) {
      nodes {
        id
        path
        date
        title
        excerpt
        slug
        categories {
          name
          link
          slug
        }
      }
    }



  }
  `;

interface IProps extends WithTranslation {
    props: any;
}
  
const post = (props:any) => {  

    // const { loading:loadingR,error:errorR, data:dataR } = useQuery(pageQuerySc)
    // const { loading,error, data } = useQuery(pageQueryEn)
    // const { i18n } = useTranslation('info');

    const { t } = useTranslation('info');
    const [ currentLang, setCurrentLang ] = useState("eng") 

    useEffect(() => {    
        setCurrentLang(t("lang"))
    }, [t]);

    // useEffect(() => {      
    //   console.log("LAN", i18n)
    // }, [i18n]);

    
  return (
    <>
            <Hero {... {
                style: headerStyle(require("src/components/Organisms/images/banner/homepage.jpg")),
                header: 'You need to know',
                subLine: '',
                buttonLink: ""
            }} />

            <div className="container-fluid section-padding-tb96 blog-page">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-sm-12 col-lg-4 order-2 align-self-start">
                    <form action="/search/">
                      <p><input className="form-control" type="text" name="q" placeholder="Search ..." /></p>
                      <input type="submit" className="custom-button theme btn btn-secondary" value="Search" />
                    </form>
                  </div>
                  <div className="col-sm-12 col-lg-8 ">
                    
                  { currentLang == "eng" ? 
                    props.data.chinese.nodes.map((post: { categories: any[]; path: any; title: any; date: moment.MomentInput; excerpt: string | null; }) => (
                      <section>
                        <img className="blog-pic" src={"/images/blog-images/" + post.categories?.[0]?.slug + ".jpg"} />
                        <h2><Link to={post.path || ''} dangerouslySetInnerHTML={{__html: post.title || ''}}></Link></h2>
                        <div className="blog-metas">
                          <div className="date">
                            <FontAwesomeIcon icon={faCalendarAlt} color="#f58d39" />
                            {moment(post.date).format('YYYY-MM-DD')}
                          </div>
                          <div className="tags">
                            {post.categories?.map((category: { link: any; name: string; }) => (
                              <div>
                                <FontAwesomeIcon icon={faTag} color="#f58d39" />
                                <a href={category?.link || '/'}>{category?.name?.replace('&amp;', '&')}</a>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="sub-cont__contet-cont__bodytxt" dangerouslySetInnerHTML={{__html: post.excerpt != null ? post.excerpt.replace(/<a class="more-link".*?<\/a>/g, '') : ''}}>
                        </div>
                      </section>
                    )) : props.data.english.nodes.map((post: { categories: any[]; path: any; title: any; date: moment.MomentInput; excerpt: string | null; }) => (
                      <section>
                        <img className="blog-pic" src={"/images/blog-images/" + post.categories?.[0]?.slug + ".jpg"} />
                        <h2><Link to={post.path || ''} dangerouslySetInnerHTML={{__html: post.title || ''}}></Link></h2>
                        <div className="blog-metas">
                          <div className="date">
                            <FontAwesomeIcon icon={faCalendarAlt} color="#f58d39" />
                            {moment(post.date).format('YYYY-MM-DD')}
                          </div>
                          <div className="tags">
                            {post.categories?.map((category: { link: any; name: string; }) => (
                              <div>
                                <FontAwesomeIcon icon={faTag} color="#f58d39" />
                                <a href={category?.link || '/'}>{category?.name?.replace('&amp;', '&')}</a>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="sub-cont__contet-cont__bodytxt" dangerouslySetInnerHTML={{__html: post.excerpt != null ? post.excerpt.replace(/<a class="more-link".*?<\/a>/g, '') : ''}}>
                        </div>
                      </section>
                    ))
                  }

                  <Pagination
                    showQuickJumper={false}
                    onChange={(next) => {
                      if(next == 1) {
                        navigate('/blog')
                      }else{
                        navigate('/blog/page/' + next)
                      }
                    }}
                    locale={{}}
                    current={props.pageContext.currentPage}
                    total={(props.pageContext.numPages * 10) / 2 } />
                  </div>
                </div>
              </div>
            </div>
          </>
      );
  
}


export default post;

// export default class Post extends React.PureComponent<{
//   data: AllWordpressPostQuery;
//   location: string;
//   pageContext: {
//     limit: number,
//     skip: number,
//     numPages: number,
//     currentPage: number
//   }

// }> {

  


//   public render() {
//     // console.log(i18n.language)
//     // const getLanguage = () => i18next.language || window.localStorage.i18nextLng
//     console.log(i18next.language)
//     console.log("====================================================")
//     // console.log(this.props.data.allWordpressPost.nodes.filter(post => post.slug?.slice(0,3) == "sn_"))
//       return (
//           <>
//             <Hero {... {
//                 style: headerStyle(require("src/components/Organisms/images/banner/homepage.jpg")),
//                 header: 'You need to know',
//                 subLine: '',
//                 buttonLink: ""
//             }} />
//             <div className="container-fluid section-padding-tb96 blog-page">
//               <div className="container">
//                 <div className="row align-items-center">
//                   <div className="col-sm-12 col-lg-4 order-2 align-self-start">
//                     <form action="/search/">
//                       <p><input className="form-control" type="text" name="q" placeholder="Search ..." /></p>
//                       <input type="submit" className="custom-button theme btn btn-secondary" value="Search" />
//                     </form>
//                   </div>
//                   <div className="col-sm-12 col-lg-8 ">
//                   {
//                     this.props.data.allWordpressPost.nodes.map(post => (
//                       <section>
//                         <img className="blog-pic" src={"/images/blog-images/" + post.categories?.[0]?.slug + ".jpg"} />
//                         <h2><Link to={post.path || ''} dangerouslySetInnerHTML={{__html: post.title || ''}}></Link></h2>
//                         <div className="blog-metas">
//                           <div className="date">
//                             <FontAwesomeIcon icon={faCalendarAlt} color="#f58d39" />
//                             {moment(post.date).format('YYYY-MM-DD')}
//                           </div>
//                           <div className="tags">
//                             {post.categories?.map(category => (
//                               <div>
//                                 <FontAwesomeIcon icon={faTag} color="#f58d39" />
//                                 <a href={category?.link || '/'}>{category?.name?.replace('&amp;', '&')}</a>
//                               </div>
//                             ))}
//                           </div>
//                         </div>
//                         <div className="sub-cont__contet-cont__bodytxt" dangerouslySetInnerHTML={{__html: post.excerpt != null ? post.excerpt.replace(/<a class="more-link".*?<\/a>/g, '') : ''}}>
//                         </div>
//                       </section>
//                     ))
//                   }
//                   <Pagination
//                     showQuickJumper={false}
//                     onChange={(next) => {
//                       if(next == 1) {
//                         navigate('/blog')
//                       }else{
//                         navigate('/blog/page/' + next)
//                       }
//                     }}
//                     locale={{}}
//                     current={this.props.pageContext.currentPage}
//                     total={this.props.pageContext.numPages * 10} />
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </>
//       );
//   }
// }

